import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './i18n.js';
import './styles/global.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// Lazy-loaded pages
const LoginPage = lazy(() => import('./pages/LoginPage'));
const LayoutComponent = lazy(() => import('./pages/LayoutComponent'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const FinancialStatusPage = lazy(() => import('./pages/FinancialStatusPage'));
const ProjectsPage = lazy(() => import('./pages/ProjectsPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const ChatPage = lazy(() => import('./pages/ChatPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'));
const NotificationsPage = lazy(() => import('./pages/NotificationsPage'));
const ProjectItemPage = lazy(() => import('./pages/ProjectItemPage'));
const TaskItemPage = lazy(() => import('./pages/TaskItemPage'));
const SupportPage = lazy(() => import('./pages/SupportPage'));

const TaskStatusManager =  lazy(() => import('./components/TaskStatusManager'));

const TasksPage = lazy(() => import('./pages/TasksPage'));
const ConfirmationUser = lazy(() => import('./pages/ConfirmationUser'));

import TestNotificationsPage from './TestNotificationsPage'; // Import the new page

// Create a Material UI theme with RTL direction and Zain font
const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: '"Zain", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

function App() {
  
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="confirm-user" element={<ConfirmationUser />} />
            
            <Route element={<LayoutComponent />}>
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="notifications" element={<NotificationsPage />} />
              <Route path="projects" element={<ProjectsPage />} />
              <Route path="projects/:projectId" element={<ProjectItemPage />} />
              <Route path="finance" element={<FinancialStatusPage />} />
              <Route path="reports" element={<ReportsPage />} />
              <Route path="tasks" element={<TasksPage />} />
              <Route path="tasks/:taskId" element={<TaskItemPage />} />
              <Route path="chat" element={<ChatPage />} />
              <Route path="settings" element={<SettingsPage />} />
              <Route path="support" element={<SupportPage />} />
              <Route path="profile" element={<UserProfilePage />} />
              <Route path="tasks-test" element={<TaskStatusManager />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
}

export default App;