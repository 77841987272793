import React, { useContext, useEffect } from 'react';
import { NotificationsContext } from './contexts/NotificationsContext'; // Import NotificationsContext
import { Box, Typography, List, ListItem, ListItemText, CircularProgress } from '@mui/material';

const TestNotificationsPage = () => {
  const { notifications, loading } = useContext(NotificationsContext); // Access notifications and loading from context

  // Listen for changes in notifications and log them to ensure they are updated
  useEffect(() => {
    if (!loading) {
      console.log("Notifications updated:", notifications);
    }
  }, [notifications, loading]);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Notifications Test Page
      </Typography>

      {/* Display loading indicator if still loading */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {/* Display notifications if available, else display 'No new notifications' */}
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={notification.title}
                  secondary={notification.body || 'No details available'}
                />
              </ListItem>
            ))
          ) : (
            <Typography>No new notifications</Typography>
          )}
        </List>
      )}
    </Box>
  );
};

export default TestNotificationsPage;