import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import './styles/global.css';
import NotificationsProvider from './contexts/NotificationsContext'; // Import NotificationsProvider

const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';

document.documentElement.setAttribute('dir', direction);

ReactDOM.render(
  <React.StrictMode>
    <NotificationsProvider> {/* Wrap the app with NotificationsProvider */}
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </NotificationsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
