// NotificationsContext.js
import React, { createContext, useState, useEffect } from 'react';
import { onMessage } from 'firebase/messaging';
import { generateToken, messaging } from '../firebase'; // Your Firebase setup

export const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Function to get the FCM token
    const fetchToken = async () => {
      try {
        const currentToken = await generateToken();
        if (currentToken) {
          console.log('FCM Token:', currentToken);
          setToken(currentToken);
        } else {
          console.log('No registration token available.');
        }
      } catch (err) {
        console.error('An error occurred while retrieving token:', err);
      }
    };

    fetchToken();

    // Listen for foreground notifications
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Foreground notification received:', payload);

      if (payload.notification) {
        const { title, body } = payload.notification;
        const newNotification = {
          title,
          body,
          data: payload.data || {},
        };
        setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
      }
    });

    // Add Event Listener for Messages from Service Worker
    let messageHandler = null;
    if ('serviceWorker' in navigator) {
      messageHandler = (event) => {
        console.log('Message received from Service Worker:', event.data);

        if (event.data && event.data.type === 'DUMMY_VARIABLE') {
          const receivedVariable = event.data.payload;
          console.log('Received dummy variable from Service Worker:', receivedVariable);
        } else if (event.data && event.data.type === 'NOTIFICATION') {
          const { notification, data } = event.data;
          const { title, body } = notification || {};
          const newNotification = {
            title,
            body,
            data: data || {},
          };
          setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
        }
      };

      navigator.serviceWorker.addEventListener('message', messageHandler);
    }

    // Clean up listeners on unmount
    return () => {
      unsubscribe();

      if ('serviceWorker' in navigator && messageHandler) {
        navigator.serviceWorker.removeEventListener('message', messageHandler);
      }
    };
  }, []);

  return (
    <NotificationsContext.Provider value={{ notifications, token }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;